import React, { useState, useEffect } from 'react'

import { useParams } from 'react-router-dom'

import 'web_common/css/LimnTech.css'
import { db } from 'web_common/tsx/api/db/Api'
import { LifemarkUserModel, Company, ProcessedPathDetail, PathPreview, HighResolutionPathData } from 'web_common/tsx/api/db/Models'

import ExportDetailForm from 'web_common/tsx/components/export/ExportDetailForm'
import ApiError from 'web_common/tsx/components/misc/ApiError'
import PermissionDenied from 'web_common/tsx/components/misc/PermissionDenied'
import Loading from 'web_common/tsx/components/misc/Loading'

interface PathExportPreviewProps {

}

const PathExportPreview: React.FC<PathExportPreviewProps> = (props) => {
    const { company_id, path_id } = useParams()

    const [apiError, setApiError] = useState<boolean>(false)
    const [lifemarkUser, setLifemarkUser] = useState<LifemarkUserModel|undefined>(undefined)
    const [company, setCompany] = useState<Company|undefined>(undefined)
    const [processedPath, setProcessedPath] = useState<ProcessedPathDetail|undefined>(undefined)
    const [highResolutionPathData, setHighResolutionPathData] = useState<Array<HighResolutionPathData>|undefined>(undefined)
    const [pathPreview, setPathPreview] = useState<PathPreview|undefined>(undefined)

    useEffect(() => {
        const onCurrentUser = (response: LifemarkUserModel|undefined) => {
            if(response === undefined)
            {
                setApiError(true)
            }
            else
            {
                setLifemarkUser(response)
            }
        }

        db.current_user(onCurrentUser)
    }, [])

    useEffect(() => {
        if(company_id !== undefined)
        {
            const onCompany = (response: Company|undefined) => {
                if(response === undefined)
                {
                    setApiError(true)
                }
                else
                {
                    setCompany(response)
                }
            }

            db.company_detail(company_id, onCompany)
        }
    }, [company_id])

    useEffect(() => {
        if(path_id !== undefined)
        {
            const onPath = (response: ProcessedPathDetail|undefined) => {
                if(response === undefined)
                {
                    setApiError(true)
                }
                else
                {
                    setProcessedPath(response)
                }
            }

            db.processed_path_detail(path_id, onPath)
        }
    }, [path_id])

    useEffect(() => {
        if(processedPath !== undefined)
        {
            const onPreview = (response: PathPreview|undefined) => {
                if(response === undefined)
                {
                    setApiError(true)
                }
                else
                {
                    setPathPreview(response)
                }
            }

            db.preview_processed_path(processedPath.preview_url, onPreview)
        }
    }, [processedPath])

    useEffect(() => {
        if(processedPath !== undefined)
        {
            const onResponse = (response: Array<HighResolutionPathData>|undefined) => {
                if(response === undefined)
                {
                    setApiError(true)
                }
                else
                {
                    setHighResolutionPathData(response)
                }
            }

            db.high_resolution_path_data_list(processedPath.id, onResponse)
        }
    }, [processedPath])

    if(apiError)
    {
        return (
            <ApiError/>
        )
    }
    else if(lifemarkUser !== undefined && company !== undefined && processedPath !== undefined
        && pathPreview !== undefined && highResolutionPathData !== undefined)
    {
        if(lifemarkUser.solo_user === false)
        {
            return (
                <PermissionDenied />
            )
        }
        else
        {
            return (
                <div className='limn-form-container'>
                    <h1 className='limn-header'> Path Export </h1>
                    <ExportDetailForm
                        company={company}
                        pathDetail={processedPath}
                        pathPreview={pathPreview}
                        exported={highResolutionPathData.length!==0}
                    />
                </div>
            )
        }
    }
    else
    {
        return (
            <div className='limn-form-container'>
                <h1 className='limn-header'> Path Export </h1>
                <Loading/>
            </div>
        )
    }
}

export default PathExportPreview
