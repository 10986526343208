import React, { useState, useEffect } from 'react';

import 'App.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import 'web_common/css/LimnTech.css'
import { db } from 'web_common/tsx/api/db/Api'
import { LifemarkUserModel } from 'web_common/tsx/api/db/Models'

import Login from 'web_common/tsx/components/auth/Login'
import Logout from 'web_common/tsx/components/auth/Logout'
import Loading from 'web_common/tsx/components/misc/Loading'
import PageNotFound from 'web_common/tsx/components/misc/PageNotFound'

import { urls } from 'InternalUrls'
import Banner from 'components/Banner'

import PathImport from 'components/import/PathImport'
import PathImportPreview from 'components/import/PathImportPreview'
import PathImportSuccess from 'web_common/tsx/components/import/PathImportSuccess'
import PathImportFailure from 'web_common/tsx/components/import/PathImportFailure'

import PathExport from 'components/export/PathExport'
import PathExportPreview from 'components/export/PathExportPreview'

function App() {
    const [loggedIn, setLoggedIn] = useState<boolean|undefined>(undefined)

    useEffect(() => {
        db.current_user(
            (response: LifemarkUserModel|undefined) =>
        {
            if(response === undefined)
            {
                setLoggedIn(false)
            }
            else
            {
                setLoggedIn(true)
            }
        })
    }, [loggedIn])

    if(loggedIn === true)
    {
        return (
            <div>
                <Router>
                    <Banner
                        showNav={true}
                    />
                    <div className='app-container'>
                        <div className='content-container'>
                            <Routes>
                                <Route path="*" element={<PageNotFound/>}/>
                                <Route path={urls.getRoot()} element={
                                    <PathImport
                                    />
                                }/>
                                <Route path={urls.getImport()} element={
                                    <PathImport
                                    />
                                }/>
                                <Route path={urls.getImportPreview(':company_id', ':path_id')} element={
                                    <PathImportPreview
                                    />
                                }/>
                                <Route path={urls.getImportSuccess()} element={
                                    <PathImportSuccess
                                    />
                                }/>
                                <Route path={urls.getImportFailure()} element={
                                    <PathImportFailure
                                    />
                                }/>
                                <Route path={urls.getExport()} element={
                                    <PathExport
                                    />
                                }/>
                                <Route path={urls.getExportPreview(':company_id', ':path_id')} element={
                                    <PathExportPreview
                                    />
                                }/>
                                <Route path={urls.getLogout()} element={
                                    <Logout
                                        redirectOnSuccess={urls.getRoot()}
                                        setLoggedIn={setLoggedIn}
                                    />
                                }/>
                            </Routes>
                        </div>
                    </div>
                </Router>
            </div>
        )
    }
    else if(loggedIn === false)
    {
        return (
            <div>
                <Router>
                <Banner
                    showNav={false}
                />
                <div className="app-container">
                    <div className="content-container">
                        <Routes>
                            <Route path="*" element={
                                <PageNotFound
                                />
                            }/>
                            <Route path={urls.getRoot()} element={
                                <Login
                                    forgotPasswordUrl={undefined}
                                    setLoggedIn={setLoggedIn}
                                />
                            }/>
                            <Route path={urls.getLogin()} element={
                                <Login
                                    forgotPasswordUrl={undefined}
                                    setLoggedIn={setLoggedIn}
                                />
                            }/>
                        </Routes>
                    </div>
                </div>
                </Router>
            </div>
        )
    }
    else
    {
        return (
            <div>
                <Router>
                    <Banner
                        showNav={false}
                    />
                    <Loading/>
                </Router>
            </div>
        )
    }
}

export default App;
