const getRoot = (): string => {
    return '/'
}

const getLogin = (): string => {
    return '/login/'
}

const getLogout = (): string => {
    return '/logout/'
}

const getImport = (): string => {
    return '/import/'
}

const getImportPreview = (company_id: string, import_id: string): string => {
    return '/import/preview/' + company_id + '/' + import_id + '/'
}

const getImportSuccess = (): string => {
    return '/import/success/'
}

const getImportFailure = (): string => {
    return '/import/failure/'
}

const getExport = (): string => {
    return '/export/'
}

const getExportPreview = (company_id: string, import_id: string): string => {
    return '/export/preview/' + company_id + '/' + import_id + '/'
}


export const urls = {
    getRoot,
    getLogin,
    getLogout,
    getImport,
    getImportPreview,
    getImportSuccess,
    getImportFailure,
    getExport,
    getExportPreview,
}
