import React, { useState } from 'react'

import { GoogleMap, useLoadScript, Polyline, Marker, InfoWindow } from '@react-google-maps/api'
import Loading from 'web_common/tsx/components/misc/Loading'
import { PatternTransitions } from 'web_common/tsx/api/service/Api'

const polyUtil = require('polyline-encoded')
const ecef = require('geodetic-to-ecef')


interface CoordinateBounds {
    minLat: number,
    minLon: number,
    maxLat: number,
    maxLon: number
}

interface GlobCoordinate {
    lat: number,
    lng: number
}

const calculateCenter = (bounds: CoordinateBounds|undefined): GlobCoordinate =>
{
    if(bounds === undefined)
    {
        return {
            lat: 37.090240,
            lng: -95.712891
        }
    }
    else
    {
        return {
            lat: (bounds.minLat + bounds.maxLat) / 2.0,
            lng: (bounds.minLon + bounds.maxLon) / 2.0
        }
    }
}

const calculateZoom = (bounds: CoordinateBounds|undefined, center: GlobCoordinate|undefined): number =>
{
    if(bounds === undefined || center === undefined)
    {
        return 4
    }
    else
    {
        /*
         * Calculate Lat Distance
         */
        let lat1_ecef = ecef(bounds.minLat, bounds.minLon)
        let lat2_ecef = ecef(bounds.maxLat, bounds.maxLon)
        let lat_dist_m = Math.sqrt(
            Math.pow(lat1_ecef[0]-lat2_ecef[0],2) +
            Math.pow(lat1_ecef[1]-lat2_ecef[1],2) +
            Math.pow(lat1_ecef[2]-lat2_ecef[2],2))

        /*
         * Calculate Lon Distance
         */
        let lon1_ecef = ecef(bounds.minLat, bounds.minLon);
        let lon2_ecef = ecef(bounds.minLat, bounds.maxLon);
        let lon_dist_m = Math.sqrt(
            Math.pow(lon1_ecef[0]-lon2_ecef[0],2) +
            Math.pow(lon1_ecef[1] - lon2_ecef[1], 2) +
            Math.pow(lon1_ecef[2] - lon2_ecef[2], 2))

        /*
         * Calc zoom
         */
        const MAX_SCREEN_PERCENT_FILL = 0.8;
        const SCREEN_WIDTH_PX = 600;
        const SCREEN_HEIGHT_PX = 600;
        const EARTH_RADIUS_M = 6378137.0;

        let screen_width_scaled = SCREEN_WIDTH_PX * MAX_SCREEN_PERCENT_FILL;
        let desired_groud_resolution = lon_dist_m / screen_width_scaled;
        let desired_map_width = (Math.cos(center.lat * Math.PI / 180.0) * 2.0 * Math.PI * EARTH_RADIUS_M) / desired_groud_resolution;
        let desired_zoom_level = (Math.log(desired_map_width / 256.0) / Math.LN2);
        let desired_zoom_height = Math.floor(desired_zoom_level);
        let screen_height_scaled = SCREEN_HEIGHT_PX * MAX_SCREEN_PERCENT_FILL;
        let desired_ground_resolution = lat_dist_m / screen_height_scaled;
        let desired_map_height = (Math.cos((center.lat * Math.PI) / 180) * 2.0 * Math.PI * EARTH_RADIUS_M) / desired_ground_resolution;
        let desired_zoom_level_ = Math.log(desired_map_height / 256.0) / Math.LN2;
        let desired_zoom_width = Math.floor(desired_zoom_level_);
        let zoom = Math.min(desired_zoom_width, desired_zoom_height);

        if(zoom > 16)
        {
            zoom = 16
        }
        else if(zoom < 2)
        {
            zoom = 2
        }

        return zoom
    }
}

const convertPatternString = (p: string): string =>
{
    if(p === "NL")
    {
        return "No Line"
    }
    else if(p === "D")
    {
        return "Solid Double"
    }
    else if(p === "S")
    {
        return "Solid Single"
    }
    else if(p === "PL")
    {
        return "Pass Left"
    }
    else if(p === "PR")
    {
        return "Pass Right"
    }
    else if(p === "SS")
    {
        return "Single Skip"
    }
    else if(p === "DMS")
    {
        return "Double Mini Skip"
    }
    else if(p === "SMS")
    {
        return "Single Mini Skip"
    }
    else if(p === "UNK")
    {
        return "Unknown"
    }
    else
    {
        return "Error"
    }
}

interface InfoWindowContentsProps {
    activeMarker: ActiveMarker|null,
    setActiveMarker: (val: ActiveMarker|null) => void,
    currentIdx: number,
    patternTransitions: Array<PatternTransitions>,
    onMarkerEdit: (e: React.FormEvent) => void,
    onMarkerDelete: (e: React.FormEvent) => void,
    onMarkerEditSave: (e: React.FormEvent) => void,
    transitionEditable: boolean
}

const EditButtons1: React.FC<InfoWindowContentsProps> = (props) =>
{
    let editButtons1 = <></>
    if(props.transitionEditable)
    {
        if(props.patternTransitions[props.currentIdx].pattern1 === "UNK" || props.patternTransitions[props.currentIdx].pattern2 === "UNK")
        {
            editButtons1 = (
                <div>
                    <div>
                        <button className='limn-button' onClick={props.onMarkerEdit}> Edit </button>
                    </div>
                </div>
            )
        }
        else
        {
            editButtons1 = (
                <div>
                    <div>
                        <button className='limn-button' onClick={props.onMarkerEdit}> Edit </button>
                    </div>
                    <div>
                        <button className='limn-button-danger' onClick={props.onMarkerDelete}> Delete </button>
                    </div>
                </div>
            )
        }
    }

    return editButtons1
}

const EditButtons2: React.FC<InfoWindowContentsProps> = (props) =>
{
    let editButtons2 = <></>
    if(props.transitionEditable)
    {
        editButtons2 = (
            <div className='limn-div-centered'>
                <button className='limn-button' onClick={props.onMarkerEditSave}> Save </button>
            </div>
        )
    }

    return editButtons2
}

interface PatternSelectorProps {
    activeMarker: ActiveMarker,
    setActiveMarker: (val: ActiveMarker|null) => void,
    pattern1: boolean
}

const PatternSelector: React.FC<PatternSelectorProps> = (props) =>
{
    let value = props.activeMarker.pattern1
    let activeMarker = props.activeMarker
    let editable: boolean = true
    let text: string = "Pattern 1"
    if(props.pattern1 === false)
    {
        text = "Pattern 2"
        value = props.activeMarker.pattern2
        if(props.activeMarker.pattern2 === "UNK")
        {
            editable = false
        }
    }
    else
    {
        if(props.activeMarker.pattern1 === "UNK")
        {
            editable = false
        }
    }

    if(editable === true)
    {
        return (
            <li>
            <b> { text } </b>
            <select
                value={value}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    let marker = {...activeMarker}
                    if(props.pattern1 === true)
                    {
                        marker.pattern1 = e.target.value
                    }
                    else
                    {
                        marker.pattern2 = e.target.value
                    }

                    props.setActiveMarker(marker)
                }}
            >
                <option
                    key={0}
                    value="NL">
                        { convertPatternString("NL") }
                </option>
                <option
                    key={0}
                    value="D">
                        { convertPatternString("D") }
                </option>
                <option
                    key={0}
                    value="S">
                        { convertPatternString("S") }
                </option>
                <option
                    key={0}
                    value="PL">
                        { convertPatternString("PL") }
                </option>
                <option
                    key={0}
                    value="PR">
                        { convertPatternString("PR") }
                </option>
                <option
                    key={0}
                    value="SS">
                        { convertPatternString("SS") }
                </option>
                <option
                    key={0}
                    value="DMS">
                        { convertPatternString("DMS") }
                </option>
                <option
                    key={0}
                    value="SMS">
                        { convertPatternString("SMS") }
                </option>
            </select>
            </li>
        )
    }
    else
    {
        return (
            <li><b>{text}</b> { convertPatternString(value) } </li>
        )
    }
}

const InfoWindowContentForm: React.FC<InfoWindowContentsProps> = (props) =>
{
    if(props.activeMarker === null || props.activeMarker.editMode === false)
    {
        return (
            <div>
                <div>
                    <ul>
                        <li>
                            <b>Color:</b> { props.patternTransitions[props.currentIdx].color }
                        </li>
                        <li>
                            <b>Pattern 1:</b> { convertPatternString(props.patternTransitions[props.currentIdx].pattern1) }
                        </li>
                        <li>
                            <b>Pattern 2:</b> { convertPatternString(props.patternTransitions[props.currentIdx].pattern2) }
                        </li>
                        <li>
                            <b>Lat:</b> { props.patternTransitions[props.currentIdx].lat }
                        </li>
                        <li>
                            <b>Lon:</b> { props.patternTransitions[props.currentIdx].lon }
                        </li>
                        <li>
                            <b>Alt:</b> { props.patternTransitions[props.currentIdx].alt }
                        </li>
                    </ul>
                </div>
                <EditButtons1
                    activeMarker={props.activeMarker}
                    setActiveMarker={props.setActiveMarker}
                    currentIdx={props.currentIdx}
                    patternTransitions={props.patternTransitions}
                    onMarkerEdit={props.onMarkerEdit}
                    onMarkerDelete={props.onMarkerDelete}
                    onMarkerEditSave={props.onMarkerEditSave}
                    transitionEditable={props.transitionEditable}
                />
            </div>
        )
    }
    else
    {
        return (
            <div>
                <div>
                    <ul>
                        <li>
                            <b>Color:</b> { props.patternTransitions[props.currentIdx].color }
                        </li>
                            <PatternSelector
                                activeMarker={props.activeMarker}
                                setActiveMarker={props.setActiveMarker}
                                pattern1={true}
                            />
                        <li>
                            <PatternSelector
                                activeMarker={props.activeMarker}
                                setActiveMarker={props.setActiveMarker}
                                pattern1={false}
                            />
                        </li>
                        <li>
                            <b>Lat:</b> { props.patternTransitions[props.currentIdx].lat }
                        </li>
                        <li>
                            <b>Lon:</b> { props.patternTransitions[props.currentIdx].lon }
                        </li>
                        <li>
                            <b>Alt:</b> { props.patternTransitions[props.currentIdx].alt }
                        </li>
                    </ul>
                </div>
                <EditButtons2
                    activeMarker={props.activeMarker}
                    setActiveMarker={props.setActiveMarker}
                    currentIdx={props.currentIdx}
                    patternTransitions={props.patternTransitions}
                    onMarkerEdit={props.onMarkerEdit}
                    onMarkerDelete={props.onMarkerDelete}
                    onMarkerEditSave={props.onMarkerEditSave}
                    transitionEditable={props.transitionEditable}
                />
            </div>
        )
    }
}

interface ActiveMarker {
    index: number,
    editMode: boolean,
    pattern1: string,
    pattern2: string,
    sn: number
}

interface EncodedPathEntry {
    encoded_path: string
}
export interface EncodedPath {
    path_list: Array<EncodedPathEntry>,
    precision: number,
}

export interface MapOverlayProps {
    encodedPaths: Array<EncodedPath>
    patternTransitionReference: Array<EncodedPath>
    patternTransitions: Array<PatternTransitions>
    setPatternTransitions: (val: Array<PatternTransitions>) => void,
    defaultTransitionColor: string,
    transitionEditable: boolean,
    showPatternAutomation: boolean,
    loaded: boolean
}

const MapOverlay: React.FC<MapOverlayProps> = (props) =>
{
    const [activeMarker, setActiveMarker] = useState<ActiveMarker|null>(null)
    const [defaultCenter, setDefaultCenter] = useState<GlobCoordinate|null>(null)
    const [viewMode, setViewMode] = useState<number>(0)

    /*
     * General things
     */
    const mapContainerStyle = {
        width: '40vw',
        height: '40vh'
    }

    let center: GlobCoordinate = {
        lat: 0,
        lng: 0,
    }
    let zoom: number = 0

    let key: string = ''
    if(process.env.REACT_APP_GOOGLE_MAPS_KEY !== undefined)
    {
        key = process.env.REACT_APP_GOOGLE_MAPS_KEY
    }

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: key
    })

    if(!isLoaded || props.loaded === false)
    {
        return (
            <div>
                <Loading />
            </div>
        )
    }
    if(loadError)
    {
        return <p> Problem Loading Google Map </p>
    }


    /*
     * This is the standard view for our google maps.
     */
    let polylines = []
    let markers = []

    if(viewMode === 0)
    {
        const options = {
            clickable: false,
            draggable: false,
            editable: false,
            visible: true,
            strokeWeight: 3,
            strokeOpacity: 1.0000,
            strokeColor: "#0000FF",
            geodesic: true,
        };

        let minLats:Array<number> = []
        let minLons:Array<number> = []
        let maxLats:Array<number> = []
        let maxLons:Array<number> = []



        let uniqueKey = 0
        for(let path_num = 0; path_num < props.encodedPaths.length; path_num++)
        {
            let encodedPath = props.encodedPaths[path_num]
            for(let i = 0; i < encodedPath.path_list.length; i++)
            {
                uniqueKey++
                let decoded_path: Array<Array<number>> = polyUtil.decode(encodedPath.path_list[i].encoded_path, encodedPath.precision)

                if(decoded_path.length === 0)
                {
                    continue
                }

                let coordinates: Array<GlobCoordinate> = decoded_path.map((obj, idx) =>
                {
                    return {
                        lat: obj[0],
                        lng: obj[1]
                    }
                })
                minLats.push(Math.min(
                    ...decoded_path.map((obj, idx) =>
                    {
                        return obj[0]
                    })
                ))
                maxLats.push(Math.max(
                    ...decoded_path.map((obj, idx) =>
                    {
                        return obj[0]
                    })
                ))
                minLons.push(Math.min(
                    ...decoded_path.map((obj, idx) =>
                    {
                        return obj[1]
                    })
                ))
                maxLons.push(Math.max(
                    ...decoded_path.map((obj, idx) =>
                    {
                        return obj[1]
                    })
                ))

                polylines.push(<Polyline
                        key={uniqueKey}
                        path={coordinates}
                        options={options}
                    />
                )
            }
        }
        if(minLats.length !== 0)
        {
            let bounds: CoordinateBounds = {
                minLat: 0,
                minLon: 0,
                maxLat: 0,
                maxLon: 0
            }

            bounds.minLat = Math.min(
                ...minLats.map((obj, idx) =>
                {
                    return obj
                })
            )
            bounds.maxLat = Math.max(
                ...maxLats.map((obj, idx) =>
                {
                    return obj
                })
            )
            bounds.minLon = Math.min(
                ...minLons.map((obj, idx) =>
                {
                    return obj
                })
            )
            bounds.maxLon = Math.max(
                ...maxLons.map((obj, idx) =>
                {
                    return obj
                })
            )

            center = calculateCenter(bounds)
            zoom = calculateZoom(bounds, center)
        }
        else
        {
            center = calculateCenter(undefined)
            zoom = calculateZoom(undefined, undefined)
        }

        if(defaultCenter !== null)
        {
            center = defaultCenter
        }
    }
    /*
     * This is the pattern coding editor view
     */
    else
    {
        const deleteMarker = (idx: number) =>
        {
            /*
             * Delete marker and make sure pattern transitions are consistent
             */
            let newMarkers: Array<PatternTransitions> = []
            for(let i = 0; i < props.patternTransitions.length; i++)
            {
                if(i + 1 === idx)
                {
                    let trans = { ...props.patternTransitions[i] }
                    if(trans.sn === props.patternTransitions[i + 1].sn)
                    {
                        trans.pattern2 = props.patternTransitions[i + 1].pattern2
                    }
                    newMarkers.push(trans)
                }
                else if(i === idx)
                {
                    continue
                }
                else
                {
                    let trans = { ...props.patternTransitions[i] }
                    newMarkers.push(trans)
                }
            }

            props.setPatternTransitions(newMarkers)
        }

        const saveMarker = () =>
        {
            if(activeMarker === null)
            {
                return
            }

            let newMarkers: Array<PatternTransitions> = []
            for(let i = 0; i < props.patternTransitions.length; i++)
            {
                if(i + 1 === activeMarker.index)
                {
                    let trans = { ...props.patternTransitions[i] }
                    if(trans.sn === activeMarker.sn)
                    {
                        trans.pattern2 = activeMarker.pattern1
                    }
                    newMarkers.push(trans)
                }
                else if(i === activeMarker.index)
                {
                    let trans = { ...props.patternTransitions[i] }
                    trans.pattern1 = activeMarker.pattern1
                    trans.pattern2 = activeMarker.pattern2
                    newMarkers.push(trans)
                }
                else if(i - 1 === activeMarker.index)
                {
                    let trans = { ...props.patternTransitions[i] }
                    if(trans.sn === activeMarker.sn)
                    {
                        trans.pattern1 = activeMarker.pattern2
                    }
                    newMarkers.push(trans)
                }
                else
                {
                    let trans = { ...props.patternTransitions[i] }
                    newMarkers.push(trans)
                }
            }

            setActiveMarker(null)
            props.setPatternTransitions(newMarkers)
        }

        for(let i = 0; i < props.patternTransitions.length; i++)
        {
            let position = {
                lat: props.patternTransitions[i].lat,
                lng: props.patternTransitions[i].lon
            }
            const onMarkerClick = (e: any) => {
                if(e.latLng !== undefined)
                {
                    let lat: number = e.latLng.lat
                    let lon: number = e.latLng.lon

                    setDefaultCenter({
                        lat: lat,
                        lng: lon
                    })
                }
                setActiveMarker({
                    index: i,
                    editMode: false,
                    pattern1: props.patternTransitions[i].pattern1,
                    pattern2: props.patternTransitions[i].pattern2,
                    sn: props.patternTransitions[i].sn,
                })
            }

            const onMarkerEdit = (e: React.FormEvent) =>
            {
                setActiveMarker({
                    index: i,
                    editMode: true,
                    pattern1: props.patternTransitions[i].pattern1,
                    pattern2: props.patternTransitions[i].pattern2,
                    sn: props.patternTransitions[i].sn
                })
                e.preventDefault()
            }

            const onMarkerEditSave = (e: React.FormEvent) =>
            {
                saveMarker()
                e.preventDefault()
            }

            const onMarkerDelete = (e: React.FormEvent) =>
            {
                deleteMarker(i)
                setActiveMarker(null)
                e.preventDefault()
            }

            markers.push(
                <Marker
                    key={i}
                    position={position}
                    onClick={onMarkerClick}
                >
                    { activeMarker !== null && activeMarker.index === i ? (
                        <InfoWindow
                            onCloseClick={()=>setActiveMarker(null)}
                        >
                            <InfoWindowContentForm
                                activeMarker={activeMarker}
                                setActiveMarker={setActiveMarker}
                                currentIdx={i}
                                patternTransitions={props.patternTransitions}
                                onMarkerEdit={onMarkerEdit}
                                onMarkerDelete={onMarkerDelete}
                                onMarkerEditSave={onMarkerEditSave}
                                transitionEditable={props.transitionEditable}
                            />
                        </InfoWindow>
                    ) : null
                    }
                </Marker>
            )
        }

        /*
         * Draw the reference path
         */
        const options = {
            clickable: true,
            draggable: false,
            editable: false,
            visible: true,
            strokeWeight: 3,
            strokeOpacity: 1.0000,
            strokeColor: "#00FF00",
            geodesic: true,
        };

        let minLats:Array<number> = []
        let minLons:Array<number> = []
        let maxLats:Array<number> = []
        let maxLons:Array<number> = []

        let uniqueKey = 0
        for(let path_num = 0; path_num < props.patternTransitionReference.length; path_num++)
        {
            let encodedPath = props.patternTransitionReference[path_num]
            for(let i = 0; i < encodedPath.path_list.length; i++)
            {
                let decoded_path: Array<Array<number>> = polyUtil.decode(encodedPath.path_list[i].encoded_path, encodedPath.precision)

                if(decoded_path.length === 0)
                {
                    continue
                }

                let coordinates: Array<GlobCoordinate> = decoded_path.map((obj, idx) =>
                {
                    return {
                        lat: obj[0],
                        lng: obj[1]
                    }
                })
                minLats.push(Math.min(
                    ...decoded_path.map((obj, idx) =>
                    {
                        return obj[0]
                    })
                ))
                maxLats.push(Math.max(
                    ...decoded_path.map((obj, idx) =>
                    {
                        return obj[0]
                    })
                ))
                minLons.push(Math.min(
                    ...decoded_path.map((obj, idx) =>
                    {
                        return obj[1]
                    })
                ))
                maxLons.push(Math.max(
                    ...decoded_path.map((obj, idx) =>
                    {
                        return obj[1]
                    })
                ))

                let keyCopy = uniqueKey
                const onLineClick = (e: any) => {
                    if(!props.transitionEditable)
                    {
                        return
                    }

                    if(e.latLng === undefined || e.latLng.lat === undefined || e.latLng.lng === undefined)
                    {
                        return
                    }

                    let lat: number = e.latLng.lat()
                    let lng: number = e.latLng.lng()
                    let closestIdx: number|undefined = undefined
                    let closeVal: number|undefined = undefined

                    for(let decodeIdx = 0; decodeIdx < decoded_path.length; decodeIdx++)
                    {
                        let latlng = decoded_path[decodeIdx]
                        let distance: number = Math.sqrt(
                            Math.pow(lat - latlng[0], 2) +
                            Math.pow(lng - latlng[1], 2))
                        if(closestIdx === undefined || closeVal === undefined)
                        {
                            closestIdx = decodeIdx
                            closeVal = distance
                        }
                        else if(distance < closeVal)
                        {
                            closestIdx = decodeIdx
                            closeVal = distance
                        }
                    }

                    if(closestIdx === undefined)
                    {
                        console.log("NO MATCH!");
                        return
                    }

                    let trans: PatternTransitions = {
                        color: props.defaultTransitionColor,
                        pattern1: "NL",
                        pattern2: "NL",
                        lat: e.latLng.lat(),
                        lon: e.latLng.lng(),
                        alt: 0.,
                        sn: keyCopy,
                        idx: closestIdx
                    }

                    /*
                     * Slot in this new transition among the previous
                     * transitions
                     */
                    let newTrans = []
                    let added: boolean = false
                    for(let transN = 0; transN < props.patternTransitions.length; transN++)
                    {
                        let oldTrans = {...props.patternTransitions[transN] }

                        if(keyCopy !== props.patternTransitions[transN].sn)
                        {
                            newTrans.push(oldTrans)
                        }
                        else
                        {
                            if(oldTrans.idx > trans.idx && added === false)
                            {
                                trans.pattern2 = oldTrans.pattern1
                                if(newTrans.length !== 0)
                                {
                                    trans.pattern1 = newTrans[newTrans.length - 1].pattern2
                                }
                                newTrans.push(trans)
                                added = true
                            }

                            newTrans.push(oldTrans)
                        }
                    }

                    if(added === false)
                    {
                        if(newTrans.length !== 0)
                        {
                            trans.pattern1 = newTrans[newTrans.length - 1].pattern2
                            trans.pattern2 = newTrans[newTrans.length - 1].pattern2
                        }
                        newTrans.push(trans)
                    }

                    setDefaultCenter({
                        lat: e.latLng.lat(),
                        lng: e.latLng.lng()
                    })
                    props.setPatternTransitions(newTrans)
                }

                polylines.push(<Polyline
                        key={uniqueKey}
                        path={coordinates}
                        options={options}
                        onClick={onLineClick}
                    />
                )
                uniqueKey++
            }
        }
        if(minLats.length !== 0)
        {
            let bounds: CoordinateBounds = {
                minLat: 0,
                minLon: 0,
                maxLat: 0,
                maxLon: 0
            }

            bounds.minLat = Math.min(
                ...minLats.map((obj, idx) =>
                {
                    return obj
                })
            )
            bounds.maxLat = Math.max(
                ...maxLats.map((obj, idx) =>
                {
                    return obj
                })
            )
            bounds.minLon = Math.min(
                ...minLons.map((obj, idx) =>
                {
                    return obj
                })
            )
            bounds.maxLon = Math.max(
                ...maxLons.map((obj, idx) =>
                {
                    return obj
                })
            )

            center = calculateCenter(bounds)
            zoom = calculateZoom(bounds, center)
        }
        else
        {
            center = calculateCenter(undefined)
            zoom = calculateZoom(undefined, undefined)
        }

        if(defaultCenter !== null)
        {
            center = defaultCenter
        }
    }

    let automationForm = <></>
    if(props.showPatternAutomation && props.patternTransitions.length !== 0)
    {
        automationForm = (
            <div className='limn-div-row limn-margin-25 limn-width-50 limn-padded-top-10px'>
                <label
                    className='limn-label-padded limn-width-200px'
                >
                    Choose Map Mode
                </label>
                <select
                    className='limn-select-box'
                    value={viewMode}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        setViewMode(parseInt(e.target.value))
                        setActiveMarker(null)
                    }}
                >
                    <option value={0}> Layout Virtual Line </option>
                    <option value={1}> Pattern Automation System </option>
                </select>
            </div>
        )
    }

    return (
        <div>
            <div>
                <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    zoom={zoom}
                    center={center}
                    onClick={()=>setActiveMarker(null)}
                >
                    { polylines }
                    { markers }
                </GoogleMap>
            </div>
            { automationForm }
        </div>
    )
}

export default MapOverlay
