import React from 'react'

import { Company } from 'web_common/tsx/api/db/Models'

interface CompanySelectionFormProps {
    companyList: Array<Company>,
    selectedCompany: Company,
    onSelection: (company: Company) => void
}

const CompanySelectionForm: React.FC<CompanySelectionFormProps> = (props) => {
    /*
     * Dynamically render components based on state
     */
    const createSelectBox = (): Array<React.ReactElement> =>
    {
        return props.companyList.map((obj, idx) =>
        {
            return <option key={idx} value={obj.id}> { obj.name } </option>
        })
    }
    const handleCompanyChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    {
        e.preventDefault()

        for(let i = 0; i < props.companyList.length; i++)
        {
            if(e.target.value === props.companyList[i].id)
            {
                props.onSelection(props.companyList[i])
                break
            }
        }
    }

    return (
        <div className="limn-div-row limn-margin-25 limn-width-50">
            <label className="limn-label-padded" htmlFor="select-company"> Select Company: </label>
            <select
                className="limn-select-box"
                id="select-company"
                name="select-company"
                value={props.selectedCompany.id}
                onChange={handleCompanyChange}>
                { createSelectBox() }
            </select>
        </div>
    );
}

export default CompanySelectionForm
