import React from 'react';
import { NavLink as NLink } from 'react-router-dom'
import { Navbar, NavLink, Nav } from 'react-bootstrap'

import 'web_common/bootstrap/css/bootstrap.css'
import 'web_common/css/Banner.css';

import limn_logo from 'web_common/img/limntech_logo.png'
import solo_logo from 'solo_logo.png'

import { urls } from 'InternalUrls'

interface BannerProps {
    showNav: boolean,
}

const Banner: React.FC<BannerProps> = (props) => {
    let nav_buttons = <></>
    if(props.showNav === true)
    {
        nav_buttons = (
            <Nav className='mr-auto lts-nav-div'>
                <NavLink as={NLink} className='lts-nav-button lts-nav-button-small lts-banner-link' to={urls.getImport()}> Import </NavLink>
                <NavLink as={NLink} className='lts-nav-button lts-nav-button-small lts-banner-link' to={urls.getExport()}> Export </NavLink>
                <NavLink as={NLink} className='lts-nav-button lts-nav-button-small lts-banner-link' to={urls.getLogout()}> Logout </NavLink>
            </Nav>
        )
    }

    return (
        <Navbar expand='lg lts-banner'>
            <Navbar.Toggle aria-controls='responsive-navbar-nav' />
            <Navbar.Collapse id='responsive-navbar-nav'>
            <Nav className='mr-auto lts-img-container'>
                <Navbar.Brand>
                    <img className="lts-banner-img" src={limn_logo} alt={''}/>
                </Navbar.Brand>
            </Nav>
            { nav_buttons }
            <Nav className='navbar-right lts-img-container'>
                <Navbar.Brand>
                    <img className="lts-banner-img" src={solo_logo} alt={''}/>
                </Navbar.Brand>
            </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default Banner;
