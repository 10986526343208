import React, { useState, useEffect } from 'react'

import 'web_common/css/LimnTech.css'

import { db } from 'web_common/tsx/api/db/Api'
import { LifemarkUserModel, Company } from 'web_common/tsx/api/db/Models'

import ApiError from 'web_common/tsx/components/misc/ApiError'
import PermissionDenied from 'web_common/tsx/components/misc/PermissionDenied'
import Loading from 'web_common/tsx/components/misc/Loading'

import PathInformationEntry from 'web_common/tsx/components/import/PathInformationEntry'

import CompanySelectionForm from 'components/misc/CompanySelectionForm'

import { urls } from 'InternalUrls'

interface PathImportProps {

}

const PathImport: React.FC<PathImportProps> = (props) => {
    const [apiError, setApiError] = useState<boolean>(false)
    const [lifemarkUser, setLifemarkUser] = useState<LifemarkUserModel|undefined>(undefined)
    const [companyList, setCompanyList] = useState<Array<Company>|undefined>(undefined)
    const [selectedCompany, setSelectedCompany] = useState<Company|undefined>(undefined)

    useEffect(() => {
        const onCurrentUser = (response: LifemarkUserModel|undefined) =>
        {
            if(response === undefined)
            {
                setApiError(true)
            }
            else
            {
                setLifemarkUser(response)
            }
        }

        db.current_user(onCurrentUser)
    }, [])

    useEffect(() => {
        const onCompanyList = (response: Array<Company>|undefined) =>
        {
            if(response === undefined)
            {
                setApiError(true)
            }
            else
            {
                response.sort(
                    (a: Company, b: Company) =>
                {
                    return a.name.localeCompare(b.name)
                })

                setCompanyList(response)
                setSelectedCompany(response[0])
            }
        }

        db.company_list(true, onCompanyList)
    }, [])

    if(apiError)
    {
        return (
            <ApiError />
        )
    }
    else if(lifemarkUser !== undefined && companyList !== undefined && selectedCompany !== undefined)
    {
        if(lifemarkUser.solo_user === false)
        {
            return (
                <PermissionDenied />
            )
        }
        else
        {
            return (
                <div className='limn-form-container'>
                    <h1 className='limn-header'> Path Import </h1>
                    <CompanySelectionForm
                        companyList={companyList}
                        selectedCompany={selectedCompany}
                        onSelection={setSelectedCompany}
                    />
                    <PathInformationEntry
                        company={selectedCompany}
                        getPreviewUrl={urls.getImportPreview}
                        hidePasImport={false}
                    />
                </div>
            )
        }
    }
    else
    {
        return (
            <div className='limn-form-container'>
                <h1 className='limn-header'> Path Import </h1>
                <Loading/>
            </div>
        )
    }
}

export default PathImport
